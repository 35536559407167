import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        my: 3,
      },
    },
    Text: {
      baseStyle: {
        my: 2,
      },
    },
  },
});

export default theme;

import React from "react";
import { navigate } from "gatsby";
import { Auth0Provider } from "@auth0/auth0-react";

const isBrowser = typeof window !== "undefined";

/**
 * A function that routes the user to the right place after login
 * @param {{ returnTo: string; }} appState
 */
const onRedirectCallback = appState => {
  navigate(appState?.returnTo || '/', { replace: true });
};

/** @type {(apiCallbackContext: { element: React.ReactNode }) => JSX.Element} */
const Auth0ProviderInstance = ({element}) => 
  <Auth0Provider
    domain={process.env.GATSBY_AUTH0_DOMAIN}
    clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
    audience={process.env.GATSBY_AUTH0_AUDIENCE}
    redirectUri={isBrowser && window.location.origin}
    onRedirectCallback={onRedirectCallback}
    useRefreshTokens={true}
    cacheLocation={isBrowser && /** @type {any} */ (window).Cypress ? 'localstorage' : 'memory'}
  >
    {element}
  </Auth0Provider>;

export default Auth0ProviderInstance;
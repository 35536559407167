import React from "react";
import Auth0Provider from './src/Auth0ProviderInstance';

/** @type {(apiCallbackContext: { element: React.ReactNode }) => JSX.Element} */
const Wrapper = ({ element }) => {
  return <Auth0Provider element={element} />;
};

export const wrapRootElement = Wrapper;

/**
 * 
 * @param {{location: import("@reach/router").WindowLocation<{ referrer: string }>, prevLocation: import("@reach/router").WindowLocation<{ referrer: string }>}} args
 */
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  const [correctedLocation, correctedPrevLocation] = prevLocation ? [prevLocation, location] : [location, prevLocation];
  correctedLocation.state = { ...correctedLocation.state, referrer: correctedPrevLocation?.href };
};
